@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&display=swap');

html {
    /* overflow-y: scroll; */
    /* overflow-y: hidden; */
    overflow-x: hidden;
    overflow-y: overlay;
}

/* .scrollbox {
    width: 10em;
    height: 10em;
    overflow: auto;
    visibility: hidden;
} */